/* Fonts */
$font-size-normal: 1rem;
$font-size-small: 0.85rem;
$line-height-base: 1.7;

/* Z-index Layers */
$zindex-layer-1: 101;
$zindex-layer-2: 102;
$zindex-layer-3: 103;

/* Responsive breakpoints */
$xs-min-width: 0;
$xs-max-width: 575px;
$sm-min-width: 576px;
$md-min-width: 768px;
$lg-min-width: 992px;
$xl-min-width: 1200px;
$xxl-min-width: 1400px;

/* Border Raduis */
$border-radius: 0.625rem;
$border-radius-sm: 0.375rem;
$border-radius-lg: 1.25rem;

$breakpoints: (
  'xs': (min-width: $xs-min-width),
  'sm': (min-width: $sm-min-width),
  'md': (min-width: $md-min-width),
  'lg': (min-width: $lg-min-width),
  'xl': (min-width: $xl-min-width),
  'xxl': (min-width: $xxl-min-width),
);

/* Transitions */
$transition-base: all .2s ease-in-out;