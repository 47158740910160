/* Responsive breakpoint manager */
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

%bg-gradient {
  background: var(--tt-primary-color);
  background: linear-gradient(to right,  var(--tt-secondary-color) 1%, var(--tt-primary-color) 100%);
}

%bg-gradient-with-hover {
  background: var(--tt-primary-color);
  background: linear-gradient(to right,  var(--tt-secondary-color) 1%, var(--tt-primary-color) 100%);

  &:hover {
    background: var(--tt-secondary-color);
    background: linear-gradient(to right,  var(--tt-primary-color) 1%, var(--tt-secondary-color) 100%);
  }
}

%text-gradient-primary {
  @extend %bg-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;

  &::selection {
      -webkit-text-fill-color: white;
  }
}