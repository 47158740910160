@import '0-abstracts/variables';

/****** Bootstrap Colors ******/

hr {
    background-color: var(--tt-border-color);
    opacity: 1;
}

.mw-430 {
    width: 100%;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
}

.mw-770 {
    width: 100%;
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;
}

.mw-570 {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
}

.asterisk {
    color: var(--tt-red-color);
}

.form-info-text {
    display: block;
    font-size: $font-size-small;
    color: var(--tt-gray-color);
}

.fs-small {
    font-size: $font-size-small;
}

.fs-6 {
    font-size: 1.15rem !important;
}

.show-password-btn {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
    background: none;
    border: none;
    transition: $transition-base;
    opacity: 0.5;

    @include respond-to('lg') {
        left: 0.85rem;
    }

    img {
        display: inline-block;
        width: 20px;
    }

    &:hover {
        opacity: 1;
    }

    span {
        display: block;
    }

    &.toggle {
        span::before {
            content: "\3f";
        }
    }
}

.btn-gradient-primary {
    @extend %bg-gradient-with-hover;
    color: #fff !important;
    border: none !important;
    padding-top: 0.445rem;
    padding-bottom: 0.445rem;

    &:hover {
        color: #fff;
    }

    &.btn-lg {
        padding-top: 0.66rem;
        padding-bottom: 0.66rem;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-primary-color-rgb), 0.25) !important;
    }

    &:focus, &:active {
        color: #fff !important;
    }
}

.btn-primary {
    background-color: var(--tt-primary-color);
    border-color: var(--tt-primary-color);
    color: #fff !important;

    &:hover, &:focus, &:active {
        background-color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) - 4%)) !important;
        border-color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) - 4%)) !important;
    }
}

.btn-secondary {
    background-color: var(--tt-secondary-color);
    border-color: var(--tt-secondary-color);
    color: var(--tt-white-color);

    &:hover {
        color: var(--tt-white-color);
        background-color: hsl(var(--tt-secondary-color-h), var(--tt-secondary-color-s), calc(var(--tt-secondary-color-l) - 4%));
        border-color: hsl(var(--tt-secondary-color-h), var(--tt-secondary-color-s), calc(var(--tt-secondary-color-l) - 4%));
    }
}

.btn-success {
    color: #fff !important;

    &:hover, &:focus, &:active {
        color: #fff !important;
    }
}

.btn-outline-danger {
    border-color: var(--tt-red-color);
    color: var(--tt-red-color) !important;

    &:hover {
        color: #fff !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-red-color-rgb), 0.25) !important;
    }
}

.btn-outline-success {
    border-color: var(--tt-green-color);
    color: var(--tt-green-color);

    &:hover {
        color: #fff;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-green-color-rgb), 0.25) !important;
    }
}


.btn-light {
    background-color: var(--tt-white-color);
    border-color: var(--tt-white-color);
    color: var(--tt-dark-color);

    &:hover {
        background-color: hsl(var(--tt-white-color-h), var(--tt-white-color-s), calc(var(--tt-white-color-l) + 4%));
        border-color: hsl(var(--tt-white-color-h), var(--tt-white-color-s), calc(var(--tt-white-color-l) + 4%));
        color: var(--tt-dark-color);
    }

    &:focus, &:active, &:focus:active {
        background-color: hsl(var(--tt-white-color-h), var(--tt-white-color-s), calc(var(--tt-white-color-l) + 4%));
        border-color: hsl(var(--tt-white-color-h), var(--tt-white-color-s), calc(var(--tt-white-color-l) + 4%));
        color: var(--tt-dark-color);
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-white-color-rgb), .25) !important;
    }
    
}

.btn-dark {
    background-color: var(--tt-dark-color);
    border-color: var(--tt-dark-color);
    color: var(--tt-white-color) !important;

    &:hover {
        color: var(--tt-white-color) !important;
        background-color: hsl(var(--tt-dark-color-h), var(--tt-dark-color-s), calc(var(--tt-dark-color-l) + 6%));
        border-color: hsl(var(--tt-dark-color-h), var(--tt-dark-color-s), calc(var(--tt-dark-color-l) + 6%));
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-dark-color-rgb), .25) !important;
    }

    &:focus, &:active, &:focus:active {
        color: var(--tt-white-color) !important;
        background-color: hsl(var(--tt-dark-color-h), var(--tt-dark-color-s), calc(var(--tt-dark-color-l) + 6%));
        border-color: hsl(var(--tt-dark-color-h), var(--tt-dark-color-s), calc(var(--tt-dark-color-l) + 6%));
    }

}

.btn-outline-gray {
    color: var(--tt-gray-color);
    border-color: var(--tt-gray-color);

    &:hover {
        color: var(--tt-dark-color);
        border-color: var(--tt-dark-color);
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-dark-color-rgb), .25) !important;
    }
}

.btn-wide {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}

.btn-lg {
    border-radius: 0.625rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.text-gray {
    color: var(--tt-gray-color) !important;
}

.link-gray {
    color: var(--tt-gray-color) !important;

    &:hover {
        color: var(--tt-primary-color) !important;
    }
}

.bg-gradient {
    @extend %bg-gradient;
}

.tooltip-inner {
    font-family: 'Somar', sans-serif;
    padding-top: 0.25rem;
    padding-bottom: 0.4rem;
    font-size: 0.75rem;
    background-color: var(--tt-dark-color);
    color: var(--tt-white-color);
    opacity: 0.95;
    border-radius: 5px;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: var(--tt-dark-color) !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: var(--tt-dark-color) !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: var(--tt-dark-color) !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: var(--tt-dark-color) !important;
}

.row-gutter-5 {
    --bs-gutter-x: 5px;
}

.border, .border-start {
    border-color: var(--tt-border-color) !important;
}

.border-dashed {
    border-style: dashed !important;
}

.border-gray-light {
    border-color: var(--tt-gray-light-color) !important;
}

a {
    color: var(--tt-primary-color);

    &:hover {
        color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) - 10%))
    }
}

.text-primary {
    color: var(--tt-primary-color) !important;
}

.text-muted {
    color: var(--tt-gray-light-color) !important;
}

.accordion-item {
    background-color: var(--tt-white-color);
    color: var(--tt-dark-color);
}

.accordion-button {
    background-color: var(--tt-white-color);
    color: var(--tt-dark-color);

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23121212' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='40' y1='128' x2='216' y2='128' fill='none' stroke='%23121212' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='128' y1='40' x2='128' y2='216' fill='none' stroke='%23121212' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3C/svg%3E");
    }
}

.accordion-button:not(.collapsed) {
    color: var(--tt-primary-color);
    background-color: rgba(var(--tt-primary-color-rgb), 0.1);
    background: var(--tt-primary-color);
    background: linear-gradient(to right,  rgba(var(--tt-secondary-color-rgb), 0.1) 1%, rgba(var(--tt-primary-color-rgb), 0.1) 100%);
    box-shadow: inset 0 calc(-1*var(--bs-accordion-border-width)) 0 var(--tt-border-color);

    &::after {
        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23641493' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='40' y1='128' x2='216' y2='128' fill='none' stroke='%23641493' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3C/svg%3E");
        transform: none;
    }
}

.accordion-button:focus {
    border-color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) + 8%));
    box-shadow: 0 0 0 0.25rem rgba(var(--tt-primary-color-rgb), 0.25);
}

.accordion-item {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: var(--tt-border-color);

    &:last-of-type {
        border-bottom: none;
    }
}

.form-check-input {
    -webkit-appearance:none;

    &:focus {
        border-color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) + 8%)) !important;
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-primary-color-rgb), 0.25);
    }
}

.form-check-input:checked {
    background-color: var(--tt-primary-color);
    border-color: var(--tt-primary-color) !important;
}

.pagination {

    .page-item:not(.active) {

        .page-link {
            color: var(--tt-primary-color);
            background-color: var(--tt-white-color);
            border-color: var(--tt-border-color);

            &::before {
                content: "";
                display: inline-block;
                height: calc($font-size-small * 1.5);
                vertical-align: middle;
            }

            &:hover {
                background-color: var(--tt-primary-color);
                border-color: var(--tt-primary-color);
                color: #fff !important;
            }
    
            &:focus {
                background-color: var(--tt-primary-color);
                box-shadow: 0 0 0 0.25rem rgba(var(--tt-primary-color-rgb), 0.25);
                color: #fff !important;
                border-color: var(--tt-primary-color);
            }
        }

        &.disabled {
            .page-link {
                color: var(--tt-gray-light-color) !important;
            }
        }

    }

    .page-item {
        &.active {

            .page-link {
                background: var(--tt-primary-color);
                background: linear-gradient(to top,  rgba(var(--tt-secondary-color-rgb), 30%) 1%, rgba(var(--tt-primary-color-rgb), 30%) 100%);
                color: var(--tt-primary-color);
                border: none;
                padding-top: 0.44rem;
                padding-bottom: 0.44rem;

                &:hover {
                    color: var(--tt-primary-color);
                }
            }
        }
    }
}

.bg-primary {
    background-color: var(--tt-primary-color) !important;

    ::selection {
        background-color: var(--tt-dark-color);
        color: var(--tt-white-color);
    }
    
}

.bg-white {
    background-color: var(--tt-white-color) !important;
}

.bg-dark {
    background-color: var(--tt-dark-color) !important;
}

.text-dark {
    color: var(--tt-dark-color) !important;
}

.text-white {
    color: var(--tt-white-color) !important;
}

.btn {
    color: var(--tt-dark-color);
    padding-top: 13px;
    padding-bottom: 13px;
}

.btn-outline-dark {
    color: var(--tt-dark-color);
    border-color: var(--tt-dark-color);

    &:hover {
        background-color: var(--tt-dark-color);
        border-color: var(--tt-dark-color);
        color: var(--tt-white-color);
    }

    &:focus, &:active, &:focus:active {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-dark-color-rgb), 0.25) !important;
        background-color: var(--tt-dark-color) !important;
        border-color: var(--tt-dark-color);
        color: var(--tt-white-color);
    }
}

.btn-outline-light {
    color: var(--tt-white-color);
    border-color: var(--tt-white-color);

    &:hover {
        background-color: var(--tt-white-color);
        border-color: var(--tt-white-color);
        color: var(--tt-dark-color);
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-white-color-rgb), 0.25) !important;
    }

    &:focus, &:active, &:focus:active {
        background-color: var(--tt-white-color);
        border-color: var(--tt-white-color);
        color: var(--tt-dark-color);
    }
}

.dropdown-menu {
    background-color: var(--tt-white-color);
    color: var(--tt-gray-color);
    border-color: var(--tt-border-color);
}

.dropdown-item {
    color: var(--tt-gray-color);

    &.active {
        background-color: var(--tt-primary-color);
    }

    &::before {
        content: "";
        display: inline-block;
        height: calc($font-size-normal * 1.7);
        vertical-align: middle;
    }

    &:hover, &:focus {
        background-color: rgba(var(--tt-dark-color-rgb), 0.06);
        color: var(--tt-dark-color);
    }
}

.dropdown-divider {
    background: none;
    border-color: var(--tt-border-color);
}

.form-control, .form-select {
    background-color: var(--tt-light-color);
    border-color: #e5e5e5;
    color: var(--tt-dark-color) !important;
    -webkit-appearance:none;
    text-align: right;

    &:disabled {
        background-color: rgba(var(--tt-dark-color-rgb), 0.06);
    }

    &:focus {
        border-color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) + 8%)) !important;
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-primary-color-rgb), 0.25);
        background-color: var(--tt-white-color);
    }
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-control-plaintext~label, .form-floating>.form-select~label {
    transform: scale(0.75) translateY(-0.5rem) translateX(-0.15rem);
}

.input-group-text {
    width: 50px;
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-to('sm') {
        width: 58px;
    }
}

.form-check-input {
    background-color: var(--tt-white-color);
    border-color: #cbcbcb;
    color: var(--tt-dark-color) !important;
}

.modal-md {
    max-width: 650px;
}

.modal-content {
    background-color: var(--tt-white-color);
    border-radius: $border-radius;
}

.modal-header {
    border-bottom-color: var(--tt-border-color);
}

.modal-footer {
    border-color: var(--tt-border-color);
}

.modal-backdrop {
    opacity: 0.7 !important;
}

.modal-fullscreen {
    .modal-header {
        border-bottom: none;
        padding: 2rem 0;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .modal-body {
        background-color: var(--tt-light-color);
    }
}

.table {
    color: var(--tt-dark-color);

    thead {
        border-bottom: 2px solid var(--tt-primary-color);
        color: var(--tt-primary-color);
        background: rgba(var(--tt-primary-color-rgb), 0.1);
        background: linear-gradient(to right,  rgba(var(--tt-secondary-color-rgb), 0.1) 1%, rgba(var(--tt-primary-color-rgb), 0.1) 100%);

        th {
            padding: 1.5rem;

            &:first-child {
                border-radius: 0 0.625rem 0 0;
            }
            &:last-child {
                border-radius: 0.625rem 0 0 0;
            }
        }

    }

    td {
        padding: 1rem;
    }

    &-sm {
        thead th {
            padding: 1.25rem;
        }

        td {
            padding: 0.75rem;
        }
    }
}

.table>:not(caption)>*>* {
    box-shadow: none;
}

.table>:not(caption)>*>* {
    border-color: var(--tt-border-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--tt-light-color);
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    background-color: var(--tt-white-color);
}

.border, .border-top, .border-left, .border-right, .border-bottom {
    border-color: var(--tt-border-color) !important;
}

.bg-light {
    background-color: var(--tt-light-color) !important;
}

.nav-pills {
    align-items: center;

    .nav-item {
        margin-left: 0.625rem;
    }

    .nav-line {
        height: 1px;
        background-color: var(--tt-border-color);
        flex-grow: 1;
    }

    .nav-link {
        background-color: var(--tt-border-color);
        color: var(--tt-gray-color);
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;

        @include respond-to('sm') {
            padding: 0.75rem 1.75rem;
        }

        @include respond-to('md') {
            padding: 1rem 3rem;
        }

        .check-icon {
            display: none;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin-right: auto;
            background-color: var(--tt-primary-color);
            color: #fff;
            border-radius: 50%;
            font-size: $font-size-small;

            @include respond-to('md') {
                width: 20px;
                height: 20px;
            }
        }

        &:not(.active) {
            &:hover {
                background: rgba(var(--tt-primary-color-rgb), 0.2) !important;
                color: var(--tt-primary-color);
            }
        }

        &.active {
            background: rgba(var(--tt-primary-color-rgb), 0.2) !important;
            color: var(--tt-primary-color);
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-top: 10px solid rgba(var(--tt-primary-color-rgb), 0.2);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }

            .check-icon {
                display: flex;
            }
        }
    }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: var(--tt-primary-color);
}

.nav-justified {

    li {
        flex-basis: 100% !important;

        @include respond-to('sm') {
            flex-basis: 0 !important;
        }
    }

    .nav-link {
        width: 100%;
    }

    .nav-link.active {
        &::before {
            content: none;
        }
    }
}

.nav-link {
    color: var(--tt-primary-color);

    &:hover {
        color: hsl(var(--tt-primary-color-h), var(--tt-primary-color-s), calc(var(--tt-primary-color-l) - 20%));
    }
}

.btn-close:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--tt-dark-color-rgb), 0.25);
}

.btn-with-icon {
    display: inline-flex;
    align-items: center;
}

.list-group-item {
    background-color: var(--tt-white-color);
    border-color: var(--tt-border-color);
}

/****** Bootstrap Customize ******/
.dropdown {
    &--without-arrow {
        .btn::after {
            content: none;
        }
    }
}

.img-responsive {
    display: block;
    width: 100%;
    max-width: 100%;
}