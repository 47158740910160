/* Import SCSS Files */
@import '0-abstracts/variables';
@import '0-abstracts/mixins';
@import '0-abstracts/colors';


@import '1-vendors/animate';


@import '2-vendors-extensions/bootstrap';


@import '3-base/global';
@import '3-base/typography';

// @import '4-layout/header';
// @import '4-layout/footer';

/******* Start Animations *******/

@keyframes heartbeat {
    0% {
        transform: scale(1) translateY(2px);
    }
    5% {
        transform: scale(1.3) translateY(2px);
    }
    10% {
        transform: scale(1.1) translateY(2px);
    }
    15% {
        transform: scale(1.5) translateY(2px);
    }
    50% {
        transform: scale(1) translateY(2px);
    }
    100% {
        transform: scale(1) translateY(2px);
    }
}

/******* Start Components *******/

/* Porgress Steps */
.progress-steps {
    display: flex;
    justify-content: center;

    &__item {
        display: block;
        flex: 1;
        height: 10px;
        border-radius: 4px;
        background-color: #D6D6D6;
        margin-left: 5px;
        margin-right: 5px;
        max-width: 100px;
        opacity: 0.5;

        &--current {
            background-color: var(--tt-primary-color);
            opacity: 1;
        }

        &--done {
            background-color: var(--tt-primary-color);
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
}

/******* Start Global *******/

.page-wrapper {

    @include respond-to('lg') {
        display: flex;
    }

    .form-area {
        text-align: center;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;

        @include respond-to('lg') {
            width: 50%;
            padding: 1rem 4rem 2rem;
        }

        @include respond-to('xl') {
            width: 45%;
        }

        @include respond-to('xxl') {
            width: 38.2%;
        }

        .back-link {
            display: inline-flex;
            margin-left: auto;
            align-items: center;
            color: var(--tt-dark-color);
            text-decoration: none;
            margin-bottom: 2rem;
            color: var(--tt-gray-light-color);

            @include respond-to('lg') {
                margin-bottom: 4rem;
            }

            &:hover {
                .icon-wrapper {
                    background-color: var(--tt-border-color);
                }
            }

            .icon-wrapper {
                // background-color: var(--tt-border-color);
                border: 1px solid var(--tt-border-color);
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-left: 0.375rem;
                padding: 6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: $transition-base;
            }
        }

        .logo-link {
            display: inline-block;
            margin-bottom: 1.175rem;
            align-self: center;
        }

        .logo-image {
            display: inline-block;
            max-width: 180px;
            max-height: 100px;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0.9rem;
        }

        p {
            margin-bottom: 1.75rem;
        }

        .form {
            display: block;
            width: 100%;
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
        }

        .form-title {
            font-weight: normal;
            font-size: 1rem;
            color: var(--tt-gray-color);
            display: flex;
            align-items: center;
            margin-top: 0;
            margin-bottom: 1.5rem;

            &::before, &::after {
                content: "";
                display: block;
                height: 1px;
                width: 50%;
                background-color: var(--tt-border-color);
                flex-grow: 1;
            }

            &::before {
                margin-left: 0.625rem;
            }

            &::after {
                margin-right: 0.625rem;
            }

            span {
                flex-shrink: 0;
            }
        }

        .copyright {
            font-size: 0.85rem;
        }

    }

    .made-by {
        margin: 0.75rem 0rem 0rem !important;
        font-size: 0.85rem;

        img {
            display: inline-block;
            width: 16px;
            height: 16px;
            transform: translateY(4px);
            animation: heartbeat 2s infinite;
        }

        a {
            color: var(--tt-gray-color);
            transition: $transition-base;

            &:hover {
                color: var(--tt-primary-color);
            }
        }
    }

    .image-area {
        display: none;
        height: 100vh;
        padding: 0 4rem;
        background: var(--tt-primary-color) url("../images/bg.jpg") no-repeat;
        background-size: cover;;
        position: sticky;
        top: 0;
        left: 0;
        color: #fff;

        @include respond-to('lg') {
            display: block;
            width: 50%;
        }
        @include respond-to('xl') {
            width: 55%;
        }
        @include respond-to('xxl') {
            width: 61.8%;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(var(--tt-dark-color-rgb), 0.85);
        }

        .image-content-wrapper {
            position: absolute;
            max-width: 750px;
            top: 50%;
            right: 4rem;
            left: 4rem;
            transform: translateY(-50%);

            h1 {
                color: #fff;
                margin-top: 0;
                margin-bottom: 2rem;
            }

            span.title-line {
                display: block;
                width: 80px;
                height: 4px;
                border-radius: 1px;
                @extend %bg-gradient;
                margin-bottom: 1.375rem;
            }

            p {
                color: #C7C7C7;
            }
        }

        .made-by {
            position: absolute;
            bottom: 2rem;
            left: 4rem;

            a {
                color: var(--tt-white-color);
            }
        }

    }
}


/******* Start Register Page *******/

.page-wrapper {
    &.register-page {

        .form-area {
    
            @include respond-to('lg') {
                width: 60%;
                padding: 1rem 4rem 2rem;
            }
    
            @include respond-to('xl') {
                width: 55%;
            }
    
            @include respond-to('xxl') {
                width: 61.8%;
            }

            .step {
                display: none;
            }

            .form {
                max-width: 680px;
            }
        }

        .image-area {
    
            @include respond-to('lg') {
                display: block;
                width: 40%;
            }
            @include respond-to('xl') {
                width: 45%;
            }
            @include respond-to('xxl') {
                width: 38.2%;
            }

        }
        
    }
}