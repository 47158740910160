/* Color Variables */
:root {
    /* Primary Color */
    --tt-primary-color: #641493;
    --tt-primary-color-rgb: 100, 20, 147;
    --tt-primary-color-h: 278;
    --tt-primary-color-s: 76%;
    --tt-primary-color-l: 33%;

    /* Secondary Color */
    --tt-secondary-color: #F62584;
    --tt-secondary-color-rgb: 246, 37, 132;
    --tt-secondary-color-h: 333;
    --tt-secondary-color-s: 92%;
    --tt-secondary-color-l: 55%;
  
    /* Dark Color */
    --tt-dark-color: #121212;
    --tt-dark-color-rgb: 18, 18, 18;
    --tt-dark-color-h: 0;
    --tt-dark-color-s: 0%;
    --tt-dark-color-l: 7%;

    /* White Color */
    --tt-white-color: #fff;
    --tt-white-color-rgb: 255, 255, 255;
    --tt-white-color-h: 0;
    --tt-white-color-s: 0%;
    --tt-white-color-l: 100%;
  
    /* Gray Color */
    --tt-gray-color: #4E4E4E;
    --tt-gray-color-rgb: 78, 78, 78;
  
    /* Gray Light Color */
    --tt-gray-light-color: #868686;
    --tt-gray-light-color-rgb: 134, 134, 134;

    /* Border Color */
    --tt-border-color: #E5E5E5;

    /* Border Light Color */
    // --tt-border-light-color: #EDEDED;
    --tt-border-light-color: #F0F0F0;
  
    /* Light Color */
    --tt-light-color: #f6f6f6;
    --tt-light-color-rgb: 246, 246, 246;
  
    /* Yellow Color */
    --tt-yellow-color: #d6a616;
    --tt-yellow-color-rgb: 214, 166, 22;
  
    /* Green Color */
    --tt-green-color: #1e9134;
    --tt-green-color-rgb: 30, 145, 52;
  
    /* Blue Color */
    --tt-blue-color: #30a7bf;
    --tt-blue-color-rgb: 48, 167, 191;
  
    /* Red Color */
    --tt-red-color: #ed2c3f;
    --tt-red-color-rgb: 237, 44, 63;
}