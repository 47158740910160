* {
    box-sizing: border-box;

    :focus {
        outline: none;
    }
    
}

html, body {
    direction: rtl;
}

body {
    overflow-x: hidden;
}

a, button {
    cursor: pointer;
}

a {
    transition: $transition-base;
}

[data-icon]:before, [class^="tticon-"]:before, [class*=" tticon-"]:before {
    display: block;
}

::selection {
    color: #fff;
    background: var(--tt-primary-color);
}

textarea {
    resize: none;
}

[class^="tticon-"], [class*=" tticon-"] {
    display: inline-block;
}

.text-gradient-primary {
    @extend %bg-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;

    &::selection {
        -webkit-text-fill-color: white;
    }
}

.text-overflow-hidden {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 65%;
}

.text-ltr {
    direction: ltr !important;
}

.heading-font {
    font-family: var(--tt-heading-font), sans-serif;
}

.btn-icon {
    font-size: 1.15rem;
    margin-left: 0.2rem;
}

.responsive-video-wrapper {
    aspect-ratio: 16/9;

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.separator-h {
    text-align: center;
    display: flex;
    align-items: center;

    span {
        flex-shrink: 0;
    }

    &::before, &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--tt-border-color);
        flex-shrink: 1;
    }

    &::before {
        margin-left: 1rem;
    }

    &::after {
        margin-right: 1rem;
    }
}

.or-separator {
    text-align: center;
    position: relative;

    &::before, &::after {
        content: '';
        display: block;
        width: calc(50% - 20px);
        height: 1px;
        background-color: var(--tt-border-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    &::after {
        right: auto;
        left: 0;
    }
}

.circle-icon {
    display: block;
    width: 120px;
    height: 120px;
    background-color: rgba(var(--tt-green-color-rgb), 0.2);
    font-size: 2.5rem;
    color: var(--tt-green-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--warning {
        background-color: rgba(var(--tt-yellow-color-rgb), 0.2);
        color: var(--tt-yellow-color);
    }

    &--danger {
        background-color: rgba(var(--tt-red-color-rgb), 0.2);
        color: var(--tt-red-color);
    }
}

.img-circle-fluid {
    display: block;
    width: 80%;
    max-width: 150px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

    @include respond-to('md') {
        max-width: 180px;
    }
}

.sortable-handle {
    cursor: move;
    cursor: grab;
}

.rate-stars {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    font-size: 1.25rem;
    color: var(--tt-yellow-color);

    span {
        display: block;
    }

    &--light {
        color: var(--tt-border-color) !important;
    }

    &--lg {
        font-size: 2rem !important;
    }

    &--xl {
        font-size: 3rem !important;
    }
}

.or-separator {
    margin: none;
    text-align: center;
    position: relative;

    &::before, &::after {
        content: '';
        display: block;
        width: calc(50% - 20px);
        height: 1px;
        background-color: var(--tt-border-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    &::after {
        right: auto;
        left: 0;
    }
}

.tooltip-icon {
    font-size: 1.25rem;
    transform: translateY(5px);
    margin-right: 0.25rem;
    color: var(--tt-gray-light-color);
}

.filters-active-button {
    border-radius: 0 0.625rem 0.625rem 0;

    .text {
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        max-width: 130px;

        @include respond-to('md') {
            max-width: 230px;
        }
    }
}

.clear-filters-button {
    border-radius: 0.625rem 0 0 0.625rem;
    background: var(--tt-border-color);
    border: none;

    &:hover {
        color: #fff;
        background-color: var(--tt-red-color);
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--tt-red-color-rgb), .25) !important;
    }
}