/*!
Font Name: Noto Kufi Arabic
Author: Google
Font URL: https://fonts.google.com/noto/specimen/Noto+Kufi+Arabic
*/

@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;400;500;700&display=swap');

@font-face {
    font-family: 'Somar';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/Somar-Thin.eot');
    src: url('../fonts/Somar-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Somar-Thin.woff2') format('woff2'),
        url('../fonts/Somar-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Somar';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Somar-Regular.eot');
    src: url('../fonts/Somar-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Somar-Regular.woff2') format('woff2'),
        url('../fonts/Somar-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Somar', sans-serif;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Somar-Medium.eot');
    src: url('../fonts/Somar-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Somar-Medium.woff2') format('woff2'),
        url('../fonts/Somar-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Somar';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/Somar-Bold.eot');
    src: url('../../fonts/Somar-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Somar-Bold.woff2') format('woff2'),
        url('../../fonts/Somar-Bold.woff') format('woff');
}

/********** Start Typography Rules **********/

:root {
    --tt-heading-font: 'Noto Kufi Arabic';
    --tt-text-font: var(--tt-heading-font);
    --tt-font-weight-light: 100;
    --tt-font-weight-normal: normal;
    --tt-font-weight-medium: 500;
    --tt-font-weight-bold: bold;
}

html {
    font-size: 14px;

    @include respond-to('sm') {
        font-size: 16px;
    }
}

body {
    font-family: var(--tt-text-font), sans-serif;
    color: var(--tt-gray-color);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: var(--tt-heading-font), sans-serif;
    font-weight: bold;
    color: var(--tt-dark-color);
}

.heading-large {
    font-size: 2.25rem;
    
    @include respond-to('md') {
        font-size: 2.5rem;
    }
}

/********** End Typography Rules **********/